// @flow

import React, { Component } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import Icon from '@conveyal/woonerf/components/icon'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table/lib/BootstrapTable'
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn'
import { CSVLink, CSVDownload } from 'react-csv'
import {Button} from 'react-bootstrap'

// import { formatHeadway } from '../../../../gtfs/util/stats'
import Loading from '../../../../common/components/Loading'
import ActiveDateTimeFilter from '../containers/ActiveDateTimeFilter'
import { getComponentMessages } from '../../../../common/util/config'
import routesReportLayoutPdf from './RoutesReportLayoutPdf'

import type {
  AllRoutesSubState,
  RouteListItem,
  ValidationPattern,
  ValidationStop
} from '../../../../types/reducers'

export type Props = {
  dateTimeFilterChange: (any) => void,
  onComponentMount: (Props) => void,
  patternFilter: ?string,
  patternFilterChange: (?ValidationPattern) => void,
  patterns: Array<ValidationPattern>,
  routeFilter: ?string,
  routeFilterChange: (?RouteListItem) => void,
  routes: AllRoutesSubState,
  stops: Array<ValidationStop>,
  tableOptions: any,
  namespace: any
}

export default class RoutesReportLayout extends Component<Props> {
  messages = getComponentMessages('RoutesReportLayout')

  // Fetch data for route report PDF
  fetchReportData = async () => {
    const { namespace } = this.props
    try {
      const response = await fetch(`${process.env.PUSA_URL}/utility/produzione-km/${namespace}`)
      if (!response.ok) throw new Error('Errore nella fetch dei dati del report.')
      const reportData = await response.json()
      return reportData
    } catch (error) {
      console.error('Errore durante il fetch dei dati per il report PDF:', error)
      return null
    }
  }

  handleGeneratePdf = async () => {
    const { routeReportData, feedInfo } = this.props
    const routeReportDataTotals = await this.fetchReportData()
    
    if (routeReportDataTotals) {
      routesReportLayoutPdf(routeReportData, routeReportDataTotals, feedInfo)
    } else {
      alert('Errore nel generare il PDF. Impossibile recuperare i dati totali.')
    }
  }

  bigNumberFormatter(cell, row){
    return cell? cell.toLocaleString('it', {maximumFractionDigits: 0}) : 0
  }
  lineNameFormatter(cell, row){
    return cell ? `<span title="${cell}">${cell}</span>`:''
  }

  componentWillMount () {
    this.props.onComponentMount(this.props.fetchStatus.fetched)
  }

  render () {
    const {
      allRoutes,
      fetchStatus: {
        error,
        fetching,
        fetched
      },
      numRoutes,
      routeReportData,
      csvPreventivo,
      csvProiezione,
      routeReportDataTotals,
      dateTimeFilterChange,
      patternFilter,
      patternFilterChange,
      patterns,
      routeFilter,
      routeFilterChange,
      routes,
      stops,
      tableOptions,
      namespace,
      feedInfo
    } = this.props
    const rowAttributes = (row, index) => {
      return {
        'data-toggle': 'popover',
        'data-placement': 'bottom',
        'data-trigger': 'hover',
        'data-content': [
          'Index: ' + index,
          'ID: ' + row.id,
          'Name: ' + row.name,
        ].join(', ')
      }
    }
    const routeTableOptions = {...tableOptions, exportCSV: false, search: true, pagination: false/*sovrascritto*/, rowAttributes}

    return (
      <div>
        {fetching &&
          <Loading />
        }
        {error &&
          <Alert bsStyle='danger'>
            {this.messages('error_fetch')}
          </Alert>
        }
        {fetched &&
        <div>
          <Row style={{marginTop: 20}}>
            <Col xs={2} style={{minWidth:'180px'}}>
              <button
                className="btn btn-warning react-bs-table-csv-btn  hidden-print"
                onClick={this.handleGeneratePdf}
              >
              <Icon type='file-text'/>  {this.messages('createRoutesReport')}
              </button>
            </Col>


          <CSVLink data={routeReportData} filename={"reportLinee.csv"}>
            <Button
              className='pull-left'
              style={{marginLeft: '10px'}}
              bsStyle={'success'}>
              <span>
                <Icon type='download'/>
                Scarica CSV corrente
              </span>
            </Button>
          </CSVLink>

          <CSVLink data={csvPreventivo} filename={"reportLineePreventivo.csv"}>
            <Button
              className='pull-left'
              style={{marginLeft: '10px'}}
              bsStyle={'success'}>
              <span>
                <Icon type='download'/>
                Scarica CSV di preventivo
              </span>
            </Button>
          </CSVLink>

          <CSVLink data={csvProiezione} filename={"reportLineeProiezione.csv"}>
            <Button
              className='pull-left'
              style={{marginLeft: '10px'}}
              bsStyle={'success'}>
              <span>
                <Icon type='download'/>
                Scarica CSV di proiezione
              </span>
            </Button>
          </CSVLink>
          {feedInfo.cod_contratto &&
            <span title="codice del contratto dell'Osservatorio Romano" style={{marginLeft: '30px', lineHeight: '34px'}}>
              <Icon type='address-card-o' />
              {' Codice contratto: '}
              {feedInfo.cod_contratto}
            </span>
          }

          </Row>

          <Row style={{marginTop: 20}}>
            <Col xs={12}>
              <BootstrapTable
                data={routeReportData}
                {...routeTableOptions}>
                <TableHeaderColumn dataSort width='200' dataField='azienda' isKey >{this.messages('agency')}</TableHeaderColumn>
                <TableHeaderColumn dataSort width='75' dataField='codiceLinea'>Linea</TableHeaderColumn>
                <TableHeaderColumn dataSort width='100%' dataField='linea' dataFormat={this.lineNameFormatter}>Nome linea</TableHeaderColumn>
                <TableHeaderColumn dataSort width='86' dataField='numPercorsi' dataAlign='right' >Percorsi</TableHeaderColumn>
                <TableHeaderColumn dataSort width='72' dataField='numCorse'  dataAlign='right'>Corse</TableHeaderColumn>
                <TableHeaderColumn dataSort width='40' dataField='tipoServizio' dataFormat={this.bigNumberFormatter} dataAlign='right' headerAlign='right'>T</TableHeaderColumn>
                <TableHeaderColumn dataSort width='80' dataField='kmDiLinea' dataFormat={this.bigNumberFormatter} dataAlign='right' headerAlign='right'>km linea</TableHeaderColumn>
                <TableHeaderColumn dataSort width='100' dataField='kmContrib' dataFormat={this.bigNumberFormatter} dataAlign='right'>km contrib</TableHeaderColumn>
                <TableHeaderColumn dataSort width='100' dataField='kmConferma' dataFormat={this.bigNumberFormatter} dataAlign='right'>km conferma</TableHeaderColumn>

            </BootstrapTable>
            </Col>
          </Row>
        </div>
        }
      </div>
    )
  }
}
