// @flow

import React, {Component} from 'react'
import Icon from '@conveyal/woonerf/components/icon'
import { Button } from 'react-bootstrap'

import { getComponentMessages } from '../../common/util/config'


type Props = {
  routeTotals: any,
}


export default class RouteSnapshotter extends Component<Props> {

  messages = getComponentMessages('TimetableHeader')

  _onClick = (name: string, route_id: string) => {
    this.props.createSnapshot(this.props.feedSource, name, '', false, false, route_id)
  }

  render () {
    const {activeEntity, } = this.props
    const {route_short_name, route_long_name, route_id} = activeEntity



    return (
      <div style={{float:'left', margin:'1em', clear:'both' }}>
          <Button
            bsStyle='primary'
            style={{ marginRight:'10px' }}
            data-test-id='take-snapshot-button'
            onClick={() => this._onClick(`linea ${route_short_name} ${route_long_name}`, route_id) }>
            <Icon type='camera' />
          </Button>
          <span>Fai uno snapshot della linea </span>
      </div>

    )
  }
}
